import { graphql, useStaticQuery } from "gatsby"

export const useCategories = () => {
  const data = useStaticQuery(
    graphql`
        query useArticleCategories {
            allContentfulPostCategory(sort: {order: ASC, fields: name}) {
                totalCount
                edges {
                    node {
                        cartgorySlug
                        id
                        name
                    }
                }
            }
        }
    `)

  return data.allContentfulPostCategory
}