import * as React from 'react';

import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import {card, cardWrapper, card_image, card_articleMeta, 
    card_summary, } from './article-card.module.scss';


const ArticleCard = ({data}) => {

    const date = new Date(data.publishDate)
    const publishDate = new Intl.DateTimeFormat("en-US", {
        dateStyle: "long",
      }).format(date);
    const authors = data.author.map((author) => {
        return author.name;

    })
    
    return (
        <a href={'/blog/' + data.slug}>
            <div className={card}>
                <div className={cardWrapper}>
                    <div className={card_image}>
                        { 
                            data.postImage !== null
                            ? <GatsbyImage alt={`${data.title} image`} image={getImage(data.postImage)} />
                            : <StaticImage src='../../assets/images/icons/user-1.png' alt={`${data.title} image`} />
                        }
                    </div>
                    <div className={card_articleMeta}>
                        <h2>{ data.title }</h2>
                        <h3>{ publishDate }</h3>
                        <h3>By: { authors }</h3>
                        <div className={card_summary}>
                            { renderRichText(data.postContent) }
                        </div>
                    </div>
                </div>
            </div>
        </a>
    )
}

export default ArticleCard