import * as React from 'react';

import Select, { InputActionMeta } from 'react-select';

import { useCategories } from '../../hooks/use-categories';

import { categories, categories_wrapper, categories_filterWrapper, active, inActive,
    categories_wrapper_large, categories_wrapper_small, categories_divider } from './article-filter-bar.module.scss';

const ArticleFilterBar = ({changeState, activeButton}) => {
    const data = useCategories()
    const cats = data.edges

    let catList = [ { label: "All Posts", value: "All Posts" } ]
    cats.map((category) =>
        catList.push({label: category.node.name,  value: category.node.name})
    )

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          backgroundColor: '#3C59A7',
          color: state.isSelected ? '#ffffff' : '#EDF1FD',
          fontFamily: 'Open Sans',
        }),
        control: () => ({
          display: 'flex',
          alignContent: 'center',
          width: 250,
          border: '1px solid #EDF1FD',
          color: '#EDF1FD',
        }),
        input: () => ({
            display: 'none',
        }),
        placeholder: () => ({
            color: '#EDF1FD',
            fontFamily: 'Open Sans',
        }),
        singleValue: () => ({
            color: '#EDF1FD',
            fontFamily: 'Open Sans',
    
        })
      }

      const onInputChange = (input, e) => {
        changeState(input)
      };
    
    return (
        <div className={categories}>
            <div className={`${categories_wrapper} ${categories_wrapper_large}`}>
                <button type="button"
                    name="All Posts"
                    className={activeButton === "All Posts" ? `${active}` : `${inActive}`}
                    value="All Posts" 
                    onClick={changeState}
                >All Posts</button>
                {cats.map((category) =>
                    <span key={category.node.id} className={categories_filterWrapper}>
                        <span className={categories_divider}>|</span>
                        <button type="button" 
                            name={category.node.name}
                            className={activeButton === category.node.name ? `${active}` : `${inActive}`}
                            value={category.node.name} 
                            onClick={changeState}>
                                { category.node.name }
                        </button>
                    </span>
                )}
            </div>
            <div className={`${categories_wrapper} ${categories_wrapper_small}`}>
                <Select name="categories" styles={customStyles} options={catList} isSearchable={true} placeholder="All Posts" 
                    defaultValue={"All Posts"} onChange={onInputChange}/>
            </div>
        </div>
    )
}

export default ArticleFilterBar