import * as React from 'react';

import ArticleCard from '../ArticleCard';

import { cardSection, cardSection_title, cardSection_contrastTitle,
    cardSection_posts } from './article-card-section.module.scss';

const ArticleCardSection = ({data}) => {
    
    return (
        <section className={cardSection}>
            <div className={cardSection_title}>
                <h2><span className={cardSection_contrastTitle}>The Latest from </span>the TGL Blog</h2>
            </div>
            <div className={cardSection_posts}>
                {data.map((post) => 
                    <div key={post.node.id}>
                        <ArticleCard data={post.node} />
                    </div>
                )}
            </div>
        </section>
    )
}

export default ArticleCardSection