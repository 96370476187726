import * as React from 'react';
import { useState } from 'react';

import { graphql, useStaticQuery } from "gatsby";

import Layout from '../components/Layout/layout';
import { Seo } from '../components/Seo/seo';

import ArticleFilterBar from '../components/ArticleFilterBar';
import ArticleCardSection from '../components/ArticleCardSection';
import PageHero from '../components/PageHero';

const BlogPage = () => {
    const data = useStaticQuery(graphql`
    query {
      allContentfulPageLayout(filter: {id: {eq: "f69d4751-774f-5517-8c05-72ee8a5c80f3"}} ){
        edges {
          node {
            name
            title
            content {
              ... on ContentfulPageHero {
                id
                name
                bannerImage {
                  gatsbyImageData(
                    width: 1440
                    height: 345
                    quality: 100
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                  )
                }
                bannerText {
                  raw
                }
                smallBannerImage {
                  gatsbyImageData(
                    width: 950
                    height: 345
                    quality: 100
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
      }
      allContentfulLocation {
        edges {
          node {
            phoneNumber
          }
        }
      }
      allContentfulBlogArticle(sort: {order: DESC, fields: publishDate}
        ) {
        totalCount
        edges {
          node {
            id
            title
            slug
            publishDate
            author {
              name
            }
            category {
              name
            }
            postContent {
              raw
            }
            postImage {
              gatsbyImage(
                quality: 100, 
                width: 280, 
                height: 280, 
                placeholder: BLURRED)
            }
          }
        }
        pageInfo {
          currentPage
          hasNextPage
          hasPreviousPage
          pageCount
          perPage
          itemCount
        }
      }
    }
  `)

  const [ category, setCategory ] = useState("All Posts")
  const [ activeCategory, setActiveCategory ] = useState("All Posts")
  const [ posts, setPosts ] = useState(data.allContentfulBlogArticle.edges)

  let filtered = data.allContentfulBlogArticle.edges

  const changeState = (e) => {
    let value = ""
    if ( e.target ) {
      value = e.target.value;
    } else if ( e.value ) {
      value = e.value;
    } else {
      value = "All Posts"
    }

    setCategory(value);

    if (value !== "All Posts") {
      filtered = data.allContentfulBlogArticle.edges.filter(post => {
        return post.node.category[0].name.includes(value)
      })
      setPosts (filtered)
    } else {
      setPosts(data.allContentfulBlogArticle.edges)
    }

    setActiveCategory(value)

  }

  const hero = data.allContentfulPageLayout.edges[0].node.content[0]

  return (
    <Layout>
      <PageHero data={ hero } />
      <ArticleFilterBar changeState={changeState} activeButton={activeCategory}/>
      <ArticleCardSection data={posts} />
    </Layout>
  )
}

export const Head = () => (
  <Seo 
    title="The Latest from the TGL Blog" 
    description="The TGL Med Staff is a team of professionals that are available at any time to answer your questions."
    pathname="/blog"
  />

)

export default BlogPage